import { getBaseExpressAppUrl } from '../config';

const expressMatch = /^.*\/portal\/?/gim;
const expressBaseUrl = getBaseExpressAppUrl();

const getE2ePath = (url = '') => {
  let basePath = '/';
  const cleanUrl = url[url.length - 1] !== '/' ? url + '/' : url;

  if (cleanUrl[0] === '/') {
    if (cleanUrl.match(expressMatch)) {
      basePath = expressBaseUrl;
    }

    return basePath + cleanUrl;
  } else {
    return url;
  }
};

export default getE2ePath;
