import styled from 'styled-components'
import { imageBlendLocations } from './HeroBanner'

export const CustomLink = styled.a`
  color: ${props => props.textColor || props.theme.colors.darkBlue};
  transition: all 0.3s;
  cursor: pointer;
  display: inline-block;
  width: auto;
  font-weight: 700;
  background-color: ${props => props.buttonColor || 'transparent'};
  text-decoration: underline;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: .5rem;
  height: 100%;
  width: 100%;

  &:hover, &:active {
    background: ${props => props.buttonColor && props.textColor ? props.textColor : 'transparent'};
    color: ${props => props.buttonColor || props.textColor || props.theme.colors.darkBlue};
  }
  
`

export default styled.section`
  margin-bottom: 3px;
  background-color: ${props => props.backgroundColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 7rem;
  box-sizing: border-box;

  @media (max-width: ${props => props.theme.breakpoints.desktopMedLarge}) {
    height: 5.5rem;
  }

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    height: 4rem;
  }

  @media (max-width: 373px) {
    height: 8rem;
  }

  @media (max-width: 293px) {
    height: 10rem;
  }

  * {
    font-family: acumin-pro;
    box-sizing: border-box;
    margin: 0;
  }

  .hero-banner {

    &__image-and-heading {
      display: flex;
      align-items: center;
      height: 100%;
    }

    &__image {
      max-width: 40%;
      height: 100%;
      position: relative;

      &__blend {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to ${props => props.imageBlendLocation === imageBlendLocations.RIGHT ? 'right' : 'left'}, transparent 80%, ${props => props.imageBlendColor});
        z-index: 5;
      }
      
      &__image {
        position: relative;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;

        img {
          height: 100%;
          object-fit: cover;
          max-width: 100%;
          object-position: 40%;


          @media (max-width: 750px) {
            object-position: 60%;
          }

          @media (max-width: 470px) {
            object-position: 90%;
          }
          
        }
      }
      
    }

    &__heading {
      padding: .5rem 1rem;
      color: ${props => props.headingColor};
      font-size: 35px;
      max-width: 65%;

      @media (max-width: 1220px) {
        font-size: 22px;
        letter-spacing: .2px;
      }

      @media (max-width: ${props => props.theme.breakpoints.tablet}) {
        font-size: 16px;
        letter-spacing: .3px;
      }
      
    }

    &__links {
      height: 100%;

      &__link {
        height: 100%;
        min-width: 20rem;

        a {
          font-size: 35px;
        }

        @media (max-width: ${props => props.theme.breakpoints.desktopMedLarge}) {
          min-width: 15rem;
          a {
            font-size: 21px;
          }
        }

        @media (max-width: ${props => props.theme.breakpoints.tablet}) {
          min-width: 6rem;
          a {
            font-size: 15px;
          }
        }
        
      }
      
    }

  }
  
`
