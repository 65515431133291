import styled from 'styled-components';

export default styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.backgroundColor};
  height: 112px;
  border-bottom: 3px solid ${props => props.borderColor};

  * {
    box-sizing: border-box;
  }
  
  .banner {

    &__image {
      height: 100%;
      max-width: 30rem;
      position: relative;
      flex-basis: 40%;

      @media (max-width: 500px) {
        flex-basis: 100%;
      }

      &__overlay {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1.2rem;

        svg {
          width: 100%;
          max-width: 16.4375rem;
        }

        @media (max-width: 400px) {
          padding: 0.5rem;
        }
        
      }

      &__gradient {
        height: 100%;
        width: 100%;
        background: linear-gradient(to right, transparent, transparent 75%, ${props => props.backgroundColor});
        position: absolute;
        top: 0;
        left: 0;
      }
      
      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }

    &__image-title-container {
      display: flex;
      align-items: center;
      height: 100%;
      flex: 0 1 auto;
    }

    &__title {
      padding: 1rem;
      padding-left: 3rem;
      
      * {
        color: ${props => props.textColor};
        margin: 0;
        padding: 0;
        font-weight: 700;
      }
    }

    &__link {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      max-width: 320px;
      border-left: 3px solid ${props => props.linkBorderColor};
      flex: 0 1 320px;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid ${props => props.linkButtonColor};
        background: ${props => props.linkButtonColor};
        color: ${props => props.linkTextColor};
        height: 100%;
        text-decoration: underline;
        width: 100%;
        font-size: 25px;
        font-weight: 700;
        cursor: pointer;
        transition: all .3s;
        text-align: center;

        &:hover {
          background: ${props => props.linkTextColor};
          color: ${props => props.linkButtonColor};
        }
        
      }
    }
    
  }

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    .banner__link a {
      font-size: 18px;
    }
  }

`;
