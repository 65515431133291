import React from 'react';
import PropTypes from 'prop-types';
import ContentStyles from './Content.styled.js';
import theme from '../../styles/theme.js';
import InView from '../Shared/InView';

const Content = ({
  children,
  align = 'left',
  width = theme.maxWidths.content,
  className = '',
  divider = 'bottom',
  threshold = 0.01,
}) => {
  return (
    <InView threshold={threshold}>
      <ContentStyles
        divider={divider}
        align={align}
        width={width}
        className={`block-content ${className}`}
      >
        <div className='inner'>{children}</div>
      </ContentStyles>
    </InView>
  );
};

Content.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  align: PropTypes.oneOf(['left', 'right', 'center']),
  divider: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  threshold: PropTypes.string,
};

export default Content;
