import React from 'react';
import { useInView } from 'react-intersection-observer';
import AccordionStyles from './Accordion.styled';
import PropTypes from 'prop-types';
import Link from '../Shared/Link';
import AccordionItem from '../Shared/AccordionItem';
import ReactMarkdown from 'react-markdown';
import remarkSubSuper from 'remark-sub-super';
import noop from '../../util/noop';
import linkRenderer from '../../util/renderers/link';

const defaultLink = {
  url: '/resources/faqs/',
  label: 'View all',
};

const Accordion = ({
  title = 'FAQs',
  link = defaultLink,
  items = [],
  isAnimated = true,
  disclaimer = '',
  useLargeHeader = false,
  id = '',
}) => {
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.25 });

  return (
    <AccordionStyles
      id={id}
      ref={ref}
      className={`block-accordion ${isAnimated ? 'isAnimated' : ''} ${
        inView ? 'active' : ''
      }`}
    >
      <div className='inner'>
        <header>
          {title && (useLargeHeader ? <h1>{title}</h1> : <h2>{title}</h2>)}
          {link && (
            <Link to={link.url} newTab={link.newTab} {...link}>
              {link.label}
            </Link>
          )}
        </header>
        <ul className='items'>
          {items.map(
            (
              {
                title,
                answer,
                slug,
                onExpand = noop,
                onReadMore = noop,
                showReadMore = true,
              },
              i
            ) => (
              <li style={{ animationDelay: `${i * 0.2 + 0.2}s` }} key={i}>
                <AccordionItem title={title} onExpand={onExpand}>
                  <ReactMarkdown
                    source={answer}
                    plugins={[remarkSubSuper]}
                    renderers={{
                      sub: 'sub',
                      sup: 'sup',
                      link: linkRenderer,
                    }}
                  />
                  {slug && showReadMore && (
                    <Link to={slug} onClick={onReadMore}>
                      Read more
                    </Link>
                  )}
                </AccordionItem>
              </li>
            )
          )}
        </ul>
        {disclaimer && <p className='disclaimer type-xs'>{disclaimer}</p>}
      </div>
    </AccordionStyles>
  );
};

Accordion.propTypes = {
  title: PropTypes.string,
  link: PropTypes.shape({
    url: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    newTab: PropTypes.bool,
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired,
      slug: PropTypes.string,
    })
  ),
  isAnimated: PropTypes.bool,
};

export default Accordion;
