import React, { useMemo, useRef } from 'react';
import StyledSVG, { NeonPath } from './NeonSvg.styles';
import PropTypes from 'prop-types';

const defaultProps = {
  onColor: '#FF9EE6',
  offColor: '#F210B3',
  transitionDuration: 200,
  animationDuration: 4032,
  frames: [
    {
      on: false,
    },
    {
      on: [1],
      off: [2],
    },
    {
      on: true,
      duration: 2,
    },
    {
      on: false,
    },
    {
      on: true,
      duration: 2,
    },
  ],
  shadow: {
    offset: 2,
    blur: 3,
    opacity: 0.25,
  },
  glow1: {
    offset: 0,
    blur: 4,
    color: '#F210B3',
    opacity: 1,
  },
  glow2: {
    offset: 0,
    blur: 4,
    color: '#F210B3',
    opacity: 1,
  },
};

export const neonAnimations = {
  promo24Q1: [
    { on: false },
    { on: [1], off: [2] },
    { on: true, duration: 2 },
    { on: false },
    { on: true, duration: 2 },
  ],
};

const NeonSvg = ({ className, ...props }) => {
  const ref = useRef(null);
  const config = useMemo(() => {
    return {
      ...defaultProps,
      ...props,
      shadow: {
        ...defaultProps.shadow,
        ...props.shadow,
      },
      glow1: {
        ...defaultProps.glow1,
        color: props.offColor || defaultProps.offColor,
        ...props.glow1,
      },
      glow2: {
        ...defaultProps.glow2,
        color: props.offColor || defaultProps.offColor,
        ...props.glow2,
      },
    };
  }, [props]);

  const { children, groups } = useMemo(() => {
    return props.children
      ? props.children.reduce(
          (output, child, i) => {
            const { group, ...childProps } = child.props;
            if (!output.groups.includes(group)) output.groups.push(group);
            if (child.type === 'path') {
              output.children.push(
                <NeonPath
                  {...childProps}
                  group={group}
                  className={`neon-path animation-group-${group}${childProps.className ? ` ${childProps.className}` : ''}`}
                  config={config}
                />
              );
            } else {
              output.children.push(child);
            }

            return output;
          },
          { children: [], groups: [] }
        )
      : { children: [], groups: [] };
  }, [props.children]);

  return (
    <StyledSVG
      ref={ref}
      config={config}
      groups={groups}
      className={'neon-sign' + className ? ` ${className}` : ''}
      viewBox={config.viewBox}
      fill={config.fill || 'none'}
    >
      {children}
    </StyledSVG>
  );
};

NeonSvg.propTypes = {
  onColor: PropTypes.string,
  offColor: PropTypes.string,
  transitionDuration: PropTypes.number,
  animationDuration: PropTypes.number,
  frames: PropTypes.arrayOf(
    PropTypes.shape({
      on: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.arrayOf(PropTypes.number),
      ]).isRequired,
      off: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.arrayOf(PropTypes.number),
      ]),
      duration: PropTypes.number,
    })
  ),
  shadow: PropTypes.shape({
    offset: PropTypes.number,
    blur: PropTypes.number,
    opacity: PropTypes.number,
  }),
  glow1: PropTypes.shape({
    offset: PropTypes.number,
    blur: PropTypes.number,
    color: PropTypes.string,
    opacity: PropTypes.number,
  }),
  glow2: PropTypes.shape({
    offset: PropTypes.number,
    blur: PropTypes.number,
    color: PropTypes.string,
    opacity: PropTypes.number,
  }),
};

export default NeonSvg;
