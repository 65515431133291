import React, { useState, useRef, useEffect } from 'react';
import AccordionItemStyles from './styled';
import PropTypes from 'prop-types';
import AccordionToggle from '../AccordionToggle';
import ReactMarkdown from 'react-markdown';
import remarkSubSuper from 'remark-sub-super';
import noop from '../../../util/noop';

const AccordionItem = ({
  title = '',
  isOpen = false,
  onExpand = noop,
  children,
  animationDuration = 0.4,
}) => {
  const [open, setOpen] = useState(isOpen);
  const [contentHeight, setContentHeight] = useState(0);
  const [childrenAreVisible, setChildrenAreVisible] = useState(isOpen);
  const contentRef = useRef();

  useEffect(() => {
    const innerWrapper = contentRef.current.querySelector('div');
    const scrollHeight = innerWrapper.scrollHeight;
    const newContentHeight = scrollHeight;

    setContentHeight(open ? newContentHeight : 0);

    setTimeout(
      () => {
        setChildrenAreVisible(open);
      },
      !open ? animationDuration * 1000 : 0
    );
  }, [open]);

  const handleExpandClick = handler => () => {
    setOpen(!open);
    if (!open) {
      handler();
    }
  };

  return (
    <AccordionItemStyles
      animationDuration={animationDuration}
      className={`accordion_item ${open ? 'open' : ''}`}
    >
      <button onClick={handleExpandClick(onExpand)} tabIndex='0'>
        <ReactMarkdown
          source={title}
          plugins={[remarkSubSuper]}
          renderers={{
            sub: 'sub',
            sup: 'sup',
          }}
        />
        <AccordionToggle isOpen={open} />
      </button>
      <section
        ref={contentRef}
        style={{
          maxHeight: `${contentHeight}px`,
        }}
        className='item-content'
      >
        <div style={{ visibility: childrenAreVisible ? 'visible' : 'hidden' }}>
          {children}
        </div>
      </section>
    </AccordionItemStyles>
  );
};

AccordionItem.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.string,
  ]),
};

export default AccordionItem;
