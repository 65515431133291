import React from 'react';
import PropTypes from 'prop-types';

const AccordionToggle = ({ isOpen }) => (
  <svg
    width='25'
    height='24'
    viewBox='0 0 25 24'
    style={{ marginTop: '.25rem' }}
  >
    <path
      d='M4.25439 12C4.25439 11.4477 4.70211 11 5.25439 11H19.2544C19.8067 11 20.2544 11.4477 20.2544 12C20.2544 12.5523 19.8067 13 19.2544 13H5.25439C4.70211 13 4.25439 12.5523 4.25439 12Z'
      fill='currentColor'
      style={{
        transform: isOpen ? '' : 'rotate(90deg)',
        transformOrigin: '50% 50%',
        transition: '.2s ease-in-out',
      }}
    />
    <path
      fill='currentColor'
      d='M4.25439 12C4.25439 11.4477 4.70211 11 5.25439 11H19.2544C19.8067 11 20.2544 11.4477 20.2544 12C20.2544 12.5523 19.8067 13 19.2544 13H5.25439C4.70211 13 4.25439 12.5523 4.25439 12Z'
    />
  </svg>
);

AccordionToggle.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default AccordionToggle;
