import React from 'react';

export default () => (
  <svg
    width='98'
    height='80'
    viewBox='0 0 98 80'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M77.45 9.31152H18.25V79.5115H77.45V9.31152Z' fill='#00A550' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M67.45 46.6113H61.75V41.0113L92.15 10.6113L97.85 16.2113L67.45 46.6113Z'
      fill='#91CCEF'
    />
    <path
      d='M61.7498 6.01172H34.0498V18.4117H61.7498V6.01172Z'
      fill='#004B8C'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M49.15 0.111328H46.65C43.35 0.111328 40.75 2.71133 40.75 6.01133H55.05C55.05 2.71133 52.45 0.111328 49.15 0.111328Z'
      fill='#004B8C'
    />
    <path
      d='M17.1499 69.6113C26.5387 69.6113 34.1499 62.0002 34.1499 52.6113C34.1499 43.2225 26.5387 35.6113 17.1499 35.6113C7.76106 35.6113 0.149902 43.2225 0.149902 52.6113C0.149902 62.0002 7.76106 69.6113 17.1499 69.6113Z'
      fill='#004B8C'
    />
    <path d='M61.7498 24.9111H34.0498V32.3111H61.7498V24.9111Z' fill='white' />
    <path
      d='M10.6997 53.4117L14.3997 57.2117L23.5997 48.0117'
      stroke='white'
      strokeWidth='3'
      strokeMiterlimit='10'
    />
  </svg>
);
