import styled from 'styled-components';

export default styled.button`
  backgroundColor: ${props => props.backgroundColor};
  width: 5rem;
  height: 5rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;

  svg {
    padding-left: 6.5px;
  }
  
`;
