import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import TestimonialStyles from './Testimonials.styled';
import ConsumerLogo from '../../../public/images/consumer-affairs-logo.png';
import loadClientReviews from '../../util/scripts/loadClientReviews';

const Testimonials = ({
  title = 'Helping families achieve homeownership for over 40 years!',
  isAnimated = true,
}) => {
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.25 });

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    loadClientReviews(() => {
      setLoaded(true);
    });
  }, []);

  return (
    <TestimonialStyles
      ref={ref}
      className={`block-testimonials ${isAnimated ? 'isAnimated' : ''} ${
        inView ? 'active' : ''
      }`}
    >
      <div className='inner'>
        <header>
          <h2>{title}</h2>
        </header>

        <div className='review-widgets-wrapper'>
          <div className='client-review-wrapper'>
            {loaded ? (
              <div id='bf-revz-widget-12345679700359'>
                <div class='bf-dv'>
                  <span className='bf-spn'>
                    {' '}
                    powered by{' '}
                    <a
                      class='bf-pwr'
                      href='https://birdeye.com?utm_source=SRC&utm_medium=widget_review-badge&utm_campaign=birdeye_widget&utm_term=powered-by-birdeye&utm_content=customer-reviews_rectangle_#00b359'
                      target='_blank'
                    >
                      Birdeye
                    </a>
                  </span>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </TestimonialStyles>
  );
};

Testimonials.propTypes = {
  title: PropTypes.string,
  isAnimated: PropTypes.bool,
};

export default Testimonials;
