import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

const TextNode = ({ search, ...props }) =>
  React.createElement(
    search.node || 'span',
    {
      style: search.style || {},
      className: search?.classes?.length ? search.classes.join(' ') : '',
      ...props,
      ...(search.props || {}),
    },
    props.children
  );

const StyleByTextSearch = ({
  node = 'div',
  children,
  text = '',
  searches = [],
  useMarkdown = false,
  markdownPlugins = [],
  markdownRenderers = {},
  ...props
}) => {
  const [outputChildren, updateOutputChildren] = useState(null);

  const recursiveReplace = (data, isInitial = true, addChild = () => {}) => {
    const parent = React.createElement(node, props, []);
    const addChildHandler = child => {
      parent.props.children.push(child);
    };

    if (typeof data === 'string') {
      let Node = data;
      let textIndex = 0;

      if (useMarkdown) {
        Node = (
          <ReactMarkdown
            source={data}
            plugins={markdownPlugins}
            renderers={{
              text: ({ children }) => {
                if (typeof children === 'string') {
                  const words = children.split(' ');
                  return (
                    <>
                      {words.map((word, index) => {
                        const breakIndex = word.indexOf('{break}');
                        const breakLineAt =
                          breakIndex === -1 ? null : breakIndex > 0 ? 1 : 0;
                        let output = null;
                        const foundSearch = searches.find(
                          search =>
                            search.text === word.trim() ||
                            search.text.includes(word.trim())
                        );

                        if (foundSearch) {
                          output = (
                            <TextNode
                              search={foundSearch}
                              key={`word-${word}-${index}`}
                            >
                              {textIndex > 0 && word !== '*' && <>&nbsp;</>}
                              {breakLineAt === 0 && (
                                <br key={`br-${word}-${index}`} />
                              )}
                              {word.replace(/\{break\}/gim, '')}
                              {breakLineAt === 1 && (
                                <br key={`br-${word}-${index}`} />
                              )}
                            </TextNode>
                          );
                        } else {
                          output = (
                            <>
                              {textIndex > 0 && word !== '*' && <>&#32;</>}
                              {breakLineAt === 0 && (
                                <br key={`br-${word}-${index}`} />
                              )}
                              {word.replace(/\{break\}/gim, '')}
                              {breakLineAt === 1 && (
                                <br key={`br-${word}-${index}`} />
                              )}
                            </>
                          );
                        }

                        textIndex++;

                        return output;
                      })}
                    </>
                  );
                }

                return <>{children}</>;
              },
              ...markdownRenderers,
            }}
          />
        );
      } else {
        const newChildren = [];
        const words = data.split(' ');
        words.forEach((word, index) => {
          let matchedSearch = null;

          searches.forEach(search => {
            if (search.text.includes(word.trim())) {
              matchedSearch = search;
            }
          });

          if (matchedSearch) {
            newChildren.push(
              <TextNode search={matchedSearch} key={`word-${word}-${index}`}>
                {word}
              </TextNode>
            );
          } else {
            newChildren.push(<>{word}</>);
          }

          if (index < words.length - 1) {
            newChildren.push(<> </>);
          }
        });

        Node = <>{newChildren}</>;
      }

      if (isInitial) {
        addChildHandler(Node);
      } else {
        addChild(Node);
      }
    } else {
      if (data?.props?.children) {
        if (typeof data.props.children === 'string') {
          const { children, ...props } = data.props;
          let Node = data.props.children;

          searches.forEach(search => {
            if (search.text.includes(children.trim())) {
              Node = () => (
                <TextNode search={search} key={`word-${word}-${index}`}>
                  {children}
                </TextNode>
              );
            }
          });

          if (isInitial) {
            addChildHandler(Node);
          } else {
            addChild(Node);
          }
        } else {
          if (data.props.children instanceof Array) {
            data.props.children.forEach(child => {
              recursiveReplace(
                child,
                false,
                isInitial ? addChildHandler : addChild
              );
            });
          } else if (typeof data?.props?.children?.props) {
            recursiveReplace(
              data.props.children,
              false,
              isInitial ? addChildHandler : addChild
            );
          }
        }
      } else if (data instanceof Array) {
        data.forEach(child => {
          recursiveReplace(
            child,
            false,
            isInitial ? addChildHandler : addChild
          );
        });
      }
    }

    return parent;
  };

  useMemo(() => {
    const content = text || children;
    if (content && content.length > 0) {
      const modChildren = recursiveReplace(content);
      updateOutputChildren(modChildren);
    }
  }, [children, text]);

  return <>{outputChildren}</>;
};

StyleByTextSearch.propTypes = {
  node: PropTypes.string,
  children: PropTypes.any,
  text: PropTypes.string,
  searches: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string || PropTypes.arrayOf(PropTypes.string),
      node: PropTypes.string,
      style: PropTypes.object,
      classes: PropTypes.arrayOf(PropTypes.string),
      props: PropTypes.object,
    })
  ),
};

export default StyleByTextSearch;
