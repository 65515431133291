import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import HeroVideoStyles from './HeroVideo.styled';
import ButtonLink from '../Shared/ButtonLink';
import Button from '../Shared/Button';
import PlayIcon from '../Shared/Icons/playFilled';
import Modal from '../Shared/Modal';
import Video from '../Shared/Video';
import Link from '../Shared/Link';
import theme from '../../styles/theme';
import noop from '../../util/noop';
import Picture from '../Shared/Picture';
import PlayButtonCircle from '../PlayButtonCircle';

const backgroundStyles = {
  circle: 'circle',
  static: 'static',
};

const videoStyles = {
  floatingBottom: 'floatingBottom',
  fullTop: 'fullTop',
};

const playButtonStyles = {
  centerCircle: 'centerCircle',
  leftBottomFull: 'leftBottomFull',
};

const ctaPositions = {
  top: 'top',
  bottom: 'bottom',
};

const configurations = {
  default: {
    backgroundStyle: backgroundStyles.circle,
    videoStyle: videoStyles.floatingBottom,
    videoMargin: '3.875rem',
    spacing: {
      ctaLeftMargin: '1rem',
    },
    colors: {
      background: theme.colors.vmfBlue,
      backgroundCircle: theme.colors.darkBlue,
      title: theme.colors.white,
      content: theme.colors.white,
    },
    ctaPosition: ctaPositions.bottom,
  },
  homeBuying: {
    backgroundStyle: backgroundStyles.static,
    videoStyle: videoStyles.fullTop,
    videoMargin: '6.1875rem',
    sizing: {
      titleMaxWidth: '400px',
      titleMaxWidthMobile: '280px',
    },
    spacing: {
      ctaLeftMargin: 0,
    },
    colors: {
      background: theme.colors.white,
      title: theme.colors.darkBlue,
      content: theme.colors.grey1,
    },
    ctaPosition: ctaPositions.top,
  },
  homeOwning: {
    backgroundStyle: backgroundStyles.static,
    videoStyle: videoStyles.fullTop,
    videoMargin: '6.1875rem',
    sizing: {
      titleMaxWidth: '400px',
      titleMaxWidthMobile: '280px',
    },
    spacing: {
      ctaLeftMargin: 0,
    },
    colors: {
      background: theme.colors.white,
      title: theme.colors.darkBlue,
      content: theme.colors.grey1,
    },
    ctaPosition: ctaPositions.bottom,
  },
};

const defaultCta = (
  <p>
    New user?
    <Link
      to='/portal/onboarding/'
      color={theme.colors.white}
      isExternal={true}
      newTab={true}
    >
      Create an account
    </Link>
  </p>
);

const HeroVideo = ({
  title = null,
  link = null,
  secondaryLink = null,
  cta = defaultCta,
  children = null,
  thumbnail = null,
  video = null,
  image = null,
  isAnimated = true,
  configuration = configurations.default,
  customConfig = null,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.5 });
  const heroConfig = useMemo(() => {
    if (configuration && configurations?.[configuration]) {
      const custom = typeof customConfig === 'object' ? customConfig : {};
      const defaultConfig =
        configurations?.[configuration] || configurations.default;

      return {
        ...(defaultConfig || {}),
        ...custom,
        sizing: {
          ...(defaultConfig?.sizing || {}),
          ...(custom?.sizing || {}),
        },
        spacing: {
          ...(defaultConfig?.spacing || {}),
          ...(custom?.spacing || {}),
        },
        colors: {
          ...(defaultConfig?.colors || {}),
          ...(custom?.colors || {}),
        },
        ctaPosition: custom?.ctaPosition || defaultConfig?.ctaPosition,
      };
    }

    return configurations.default;
  }, [configuration, customConfig]);

  const handlePlayClick = handler => () => {
    setModalOpen(true);
    handler();
  };

  return (
    <HeroVideoStyles
      ref={ref}
      className={`block-hero_video ${isAnimated ? 'isAnimated' : ''} ${
        inView ? 'active' : ''
      }`}
      config={heroConfig}
      playButtonStyle={video?.playButtonStyle}
      playButtonStyles={playButtonStyles}
      videoStyle={heroConfig.videoStyle}
      videoStyles={videoStyles}
      ctaPosition={heroConfig.ctaPosition}
      ctaPositions={ctaPositions}
    >
      <div className='inner'>
        <div className='primary'>
          <h1 className='type-large'>{title}</h1>
          <div className='content'>{children}</div>
          <div className='links-and-cta-wrapper'>
            <div className='links'>
              {link && link.to && link.title && (
                <ButtonLink appearance='light' {...link}>
                  {link.title}
                </ButtonLink>
              )}
              {secondaryLink && secondaryLink.to && secondaryLink.title && (
                <ButtonLink
                  {...secondaryLink}
                  appearance='light'
                  outline={true}
                >
                  {secondaryLink.title}
                </ButtonLink>
              )}
            </div>
            <div className='cta'>{cta}</div>
          </div>
        </div>
        <div className='secondary'>
          <Picture
            src={`${thumbnail?.src || image?.src}?w=626`}
            alt={thumbnail?.alt || image?.alt}
            onClick={video ? () => setModalOpen(true) : noop}
            sources={[
              {
                media: `(min-width: ${theme.breakpoints.desktop})`,
                params: '?w=626',
                srcSet: thumbnail?.src || image?.src,
              },
              {
                media: `(min-width: ${theme.breakpoints.tablet})`,
                params: '?w=512',
                srcSet: thumbnail?.src || image?.src,
              },
              {
                media: `(max-width: ${theme.breakpoints.tablet})`,
                params: '?w=300',
                srcSet: thumbnail?.src || image?.src,
              },
            ]}
            isResponsive
          >
            {thumbnail?.text && (
              <h1 className='type-h1 thumbnail-text'>{thumbnail.text}</h1>
            )}
            {video &&
              video.content &&
              (!video?.playButtonStyle ||
                video?.playButtonStyle === playButtonStyles.leftBottomFull) && (
                <Button
                  onClick={handlePlayClick(video.onClick || noop)}
                  appearance='light'
                >
                  <PlayIcon />
                  <p>Watch: Home Loan Process</p>
                </Button>
              )}
            {video &&
              video.content &&
              video?.playButtonStyle === playButtonStyles.centerCircle && (
                <PlayButtonCircle onClick={video.onClick || noop} />
              )}
          </Picture>
        </div>
      </div>
      {heroConfig.backgroundStyle === backgroundStyles.circle && (
        <div className='background-circle' />
      )}
      {video && modalOpen && (
        <Modal onClose={() => setModalOpen(false)}>
          <Video {...video}></Video>
        </Modal>
      )}
    </HeroVideoStyles>
  );
};

HeroVideo.propTypes = {
  title: PropTypes.string,
  link: PropTypes.shape({
    to: PropTypes.string,
    title: PropTypes.string,
  }),
  secondaryLink: PropTypes.shape({
    to: PropTypes.string,
    title: PropTypes.string,
  }),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  thumbnail: PropTypes.shape({
    alt: PropTypes.string,
    src: PropTypes.string,
    text: PropTypes.string,
  }),
  video: PropTypes.shape({
    source: PropTypes.oneOf(['Wistia', 'YouTube']),
    content: PropTypes.string,
    playButtonStyle: PropTypes.oneOf(Object.keys(playButtonStyles)),
  }),
  isAnimated: PropTypes.bool,
  configuration: PropTypes.oneOf(Object.keys(configurations)),
  customConfig: PropTypes.shape({
    backgroundStyle: PropTypes.oneOf(Object.keys(backgroundStyles)),
    videoStyle: PropTypes.oneOf(Object.keys(videoStyles)),
    videoMargin: PropTypes.any,
    sizing: PropTypes.shape({
      titleMaxWidth: PropTypes.string,
    }),
    spacing: PropTypes.shape({
      ctaLeftMargin: PropTypes.any,
    }),
    colors: PropTypes.shape({
      background: PropTypes.string,
      backgroundCircle: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.string,
    }),
  }),
};

export default HeroVideo;
