const categories = [
  {
    slug: 'your-mortgage',
    label: 'Your Mortgage',
    title: 'Your Mortgage FAQs | Vanderbilt Mortgage',
    description:
      'Find answers to common questions about your mortgage from Vanderblit Mortage and Finance, Inc.. If you have any more questions, contact us! We are always happy to help you better understand this process.',
  },
  {
    slug: 'pre-owned-financing',
    label: 'Pre-owned Financing',
    title: 'Pre-owned Financing | Vanderbilt Mortgage',
    description:
      'Learn more about whether you can finance a home that is or will be located in a park, community, or on a rented lot.',
  },
  {
    slug: 'insurance',
    label: 'Insurance',
    title: 'Insurance FAQs | Vanderbilt Mortgage',
    description:
      'Find answers to common questions about insurance from Vanderblit Mortage and Finance, Inc. If you have any more questions, contact us! We are always happy to help you better understand this process.',
  },
  {
    slug: 'property-taxes-forms',
    label: 'Property Taxes & Tax Forms',
    title: 'Property Tax & Tax Form FAQs | Vanderbilt Mortgage',
    description:
      'Find answers to common questions about property taxes and tax forms from Vanderblit Mortage and Finance, Inc.. If you have any more questions, contact us! We are always happy to help you better understand this process.',
  },
  {
    slug: 'payment',
    label: 'Payment',
    title: 'Payment FAQs | Vanderbilt Mortgage',
    description:
      'Find answers to common questions about payments from Vanderblit Mortage and Finance, Inc. If you have any more questions, contact us! We are always happy to help you better understand this process.',
  },
  {
    slug: 'your-home',
    label: 'Your Home',
    title: 'Your Home FAQs | Vanderbilt Mortgage',
    description:
      'Find answers to common questions about your home from Vanderblit Mortage and Finance, Inc. If you have any more questions, contact us! We are always happy to help you better understand this process.',
  },
  {
    slug: 'my-vmf-account',
    label: 'My VMF Account',
    title: 'My VMF Account FAQs | Vanderbilt Mortgage',
    description:
      'Find answers to common questions about your VMF account from Vanderblit Mortage and Finance, Inc. If you have any more questions, contact us! We are always happy to help you better understand this process.',
  },
];

export const slugByLabel = category =>
  categories.find(({ label }) => label === category)?.slug;

export const labelBySlug = category =>
  categories.find(({ slug }) => slug === category)?.label;

export const titleBySlug = category =>
  categories.find(({ slug }) => slug === category)?.title;

export const descriptionBySlug = category =>
  categories.find(({ slug }) => slug === category)?.description;
