import styled from 'styled-components';

const ContentStyles = styled.section`
  padding: 3.0625rem ${props => props.theme.padding.inner} 3.375rem;
  border-bottom: ${props =>
    props.divider && props.divider === 'bottom'
      ? `solid 1px ${props.theme.colors.grey5}`
      : 'none'};
  border-left: ${props =>
    props.divider && props.divider === 'left'
      ? `solid 1px ${props.theme.colors.grey5}`
      : 'none'};
  transition: all 0.4s ease-in-out;

  .active& {
    border-bottom: ${props =>
      props.divider && props.divider === 'bottom'
        ? `solid 1px ${props.theme.colors.grey5}`
        : 'none'};
    border-left: ${props =>
      props.divider && props.divider === 'left'
        ? `solid 1px ${props.theme.colors.grey5}`
        : 'none'};
  }

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 5.3125rem ${props => props.theme.padding.inner} 5.5625rem;
  }

  .inner {
    max-width: ${props => props.theme.maxWidths.containerSmall};
    margin: auto;
    text-align: ${props => props.align};

    .active& {
      opacity: 1;
      transform: none;
    }

    @media (min-width: ${props => props.theme.breakpoints.desktop}) {
      max-width: ${props => props.width};
    }

    img {
      width: 100%;
      border-radius: 4px;
      overflow: hidden;
    }
  }
`;

export default ContentStyles;
