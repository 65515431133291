import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import sa from '../../../styles/Components/SimpleAnimation';
import Styles, { CustomLink } from './HeroBanner.styled';
import theme from '../../../styles/theme';
import Picture from '../../Shared/Picture';
import CustomUrl from '../../../util/url/CustomUrl';

export const imageLocations = {
  CENTER: 'Center',
  LEFT: 'Left Side',
  RIGHT: 'Right Side',
  // TOP: 'Top', !Has not been implemented yet!
  // BOTTOM: 'Bottom' !Has not been implemented yet!
};

export const imageSettings = {
  FULL_IMAGE: 'Full Image',
  INSET_IMAGE: 'Inset Image',
  FULL_VIDEO: 'Full Video',
  INSET_VIDEO: 'Inset Video',
};

export const imageBlendLocations = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
};

const HeroBanner = ({
  image,
  imageSources,
  imageLocation = imageLocations.LEFT,
  imageSetting = imageLocation.FULL_IMAGE,
  backgroundColor = theme.colors.darkBlue,
  imageBlendColor = null,
  imageBlendLocation = imageBlendLocations.RIGHT,
  heading = '',
  headingColor = theme.colors.white,
  link = {},
}) => {
  const location = useLocation();

  const linkHref = useMemo(() => {
    const pathWithQuery = new CustomUrl(link?.href);
    pathWithQuery.joinQuery(location.search);
    return pathWithQuery.fullUrl;
  }, [location, link]);

  return (
    <Styles
      imageLocation={imageLocation}
      backgroundColor={backgroundColor}
      headingColor={headingColor}
      imageBlendColor={imageBlendColor}
      imageBlendLocation={imageBlendLocation}
    >
      <div className='hero-banner__image-and-heading'>
        {image?.src && (
          <div className='hero-banner__image'>
            {imageBlendColor && <div className='hero-banner__image__blend' />}
            <Picture
              className='hero-banner__image__image'
              src={image?.src}
              alt={image?.alt}
              isResponsive={true}
              sources={imageSources}
            />
          </div>
        )}
        {heading && <h3 className='hero-banner__heading'>{heading}</h3>}
      </div>
      <div className='hero-banner__links'>
        <sa.div
          className='hero-banner__links__link'
          slideDirection='up'
          delay={0.4}
        >
          <CustomLink
            target={link?.href?.[0] === '/' ? '_blank' : '_self'}
            rel='noopener noreferrer'
            buttonColor={link?.buttonColor}
            textColor={link?.textColor}
            href={linkHref}
          >
            {link?.text}
          </CustomLink>
        </sa.div>
      </div>
    </Styles>
  );
};

HeroBanner.propTypes = {
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
  imageSources: PropTypes.arrayOf(
    PropTypes.shape({
      media: PropTypes.string,
      params: PropTypes.string,
      srcSet: PropTypes.string,
    })
  ),
  imageLocation: PropTypes.oneOf(Object.values(imageLocations)),
  imageSetting: PropTypes.oneOf(Object.values(imageSettings)),
  backgroundColor: PropTypes.string,
  imageBlendColor: PropTypes.string,
  imageBlendLocation: PropTypes.oneOf(Object.values(imageBlendLocations)),
  heading: PropTypes.string,
  headingColor: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      buttonColor: PropTypes.string,
      textColor: PropTypes.string,
      linkType: PropTypes.oneOf(['Text Link', 'Button']),
      href: PropTypes.string,
      // icon: PropTypes.shape({}),
      text: PropTypes.string,
    })
  ),
};

export default HeroBanner;
