import { useRef } from 'react';
import styled, { css } from 'styled-components';

const nodes = [
  'section',
  'header',
  'div',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'p',
  'a',
  'button',
  'li',
  'input',
  'footer',
  'img',
  'hr',
];

const timingFunctions = {
  'ease-in': 'ease-in',
  'ease-out': 'ease-out',
  'ease-in-out': 'ease-in-out',
};

const slideDirections = {
  up: 'up',
  down: 'down',
  left: 'left',
  right: 'right',
};

const generateAnimationID = () => {
  const a = (Math.random() * 46656) | 0;
  const b = (Math.random() * 46656) | 0;
  return (
    ('000' + a.toString(36)).slice(-3) + ('000' + b.toString(36)).slice(-3)
  );
};

const numbersOnly = str => `${str}`.replace(/[^0-9\.\-]/gim, '');

const createOpacityString = opacity => `opacity: ${numbersOnly(opacity)};`;
const createTransformString = (slideDirection, strength, isStartAnimation) => {
  const { up, down, left, right } = slideDirections;
  const isVertical = [up, down].includes(slideDirection);
  const isNegative =
    (isStartAnimation && [down, right].includes(slideDirection)) ||
    (!isStartAnimation && [up, left].includes(slideDirection));
  const value = `${isNegative ? '-' : ''}${numbersOnly(strength)}px`;
  return `transform: translate${isVertical ? 'Y' : 'X'}(${!isStartAnimation ? 0 : value});`;
};

const styles = ({
  shouldAnimate = true,
  animateWhen = true,
  shouldSlide = true,
  shouldFade = true,
  slideDirection = slideDirections.down,
  slideStrength = 15,
  duration = 0.4,
  delay = 0.2,
  ease = timingFunctions['ease-in-out'],
  waitUntilInView = true,
  inView = undefined,
}) => {
  const animationName = useRef(`sa${generateAnimationID()}`);
  const keyframe =
    shouldAnimate && (shouldSlide || shouldFade)
      ? css`
          @keyframes ${animationName.current} {
            from {
              ${shouldFade ? createOpacityString(0) : ''}
              ${shouldSlide
                ? createTransformString(slideDirection, slideStrength, true)
                : ''}
            }
            to {
              ${shouldFade ? createOpacityString(1) : ''}
              ${shouldSlide
                ? createTransformString(slideDirection, slideStrength, false)
                : ''}
            }
          }
        `
      : css``;

  const defaultCssValues =
    shouldAnimate && (shouldSlide || shouldFade)
      ? css`
          ${shouldFade ? createOpacityString(0) : ''}
          ${shouldSlide
            ? createTransformString(slideDirection, slideStrength, true)
            : ''}
        `
      : css``;

  const animationCssValues =
    shouldAnimate &&
    animateWhen &&
    (waitUntilInView && inView !== undefined ? inView : true)
      ? css`
          animation-name: ${animationName.current};
          animation-timing-function: ${timingFunctions[ease]};
          animation-duration: ${numbersOnly(duration)}s;
          animation-delay: ${numbersOnly(delay)}s;
          animation-fill-mode: forwards;
        `
      : css``;

  return shouldAnimate
    ? css`
        @media (prefers-reduced-motion: no-preference) {
          ${keyframe}
          ${defaultCssValues}
        ${animationCssValues}
        }
      `
    : '';
};

export default nodes.reduce(
  (output, current) => {
    output[current] = styled[current]`
      ${styles}
    `;
    return output;
  },
  {
    custom: comp => styled(comp)`
      ${styles}
    `,
  }
);
