import React from 'react';

export default () => (
  <svg
    width='10'
    height='13'
    viewBox='0 0 10 13'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M9.36286 5.92403C9.66275 6.12161 9.66275 6.5615 9.36286 6.75908L1.31537 12.0612C0.982919 12.2802 0.540283 12.0417 0.540283 11.6436L0.540283 1.03948C0.540283 0.641357 0.98292 0.402917 1.31537 0.62195L9.36286 5.92403Z'
      fill='currentColor'
    />
  </svg>
);
