const loadClientReviews = callback => {
  const existingScript = document.getElementById(
    'bf-revz-widget-12345679700359'
  );

  if (existingScript) {
    existingScript.remove();
  }

  const script = document.createElement('script');
  script.src = 'https://birdeye.com/embed/v4/158108530018176/7/12345679700359';

  script.id = 'bf-revz-widget-12345679700359';
  document.body.appendChild(script);

  script.onload = () => {
    if (callback) callback();
  };
};

export default loadClientReviews;
