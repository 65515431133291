import React from 'react';
import theme from '../../../../styles/theme';
import CloudImage from '../../../../../public/images/clouds.png';
import ButtonLink from '../../../Shared/ButtonLink';
import Picture from '../../../Shared/Picture';
import Styles from './HeroOverride.styled';
import StyleByTextSearch from '../../../../util/components/StyleByTextSearch';
import ReactMarkdown from 'react-markdown';
import Link from '../../../Shared/Link';
import getE2ePath from '../../../../util/url/getE2ePath';

const HeroOverride = ({
  sectionHeader = '',
  sectionIntro = '',
  sectionLabel = '',
  sectionSubHeader = '',
  heroImages = {},
  links = [],
}) => {
  return (
    <Styles>
      <div className='hero-override-section-main text-content'>
        <div className='hero-override-main-heading'>
          <StyleByTextSearch
            text={sectionLabel}
            className='main-heading'
            searches={[
              {
                text: 'Five!',
                classes: ['main-heading-accent'],
              },
            ]}
            useMarkdown
          />
          <div className='clouds-image'>
            <img src={CloudImage} alt='White clouds' />
          </div>
          <div className='sub-heading'>
            {/* <StyleByTextSearch
              text={sectionSubHeader}
              className="inner"
              searches={[
                {
                  text: '5%',
                  classes: ['main-heading-accent', 'main-heading-accent__large']
                },
                {
                  text: 'off',
                  classes: ['main-heading-accent', 'main-heading-accent__small']
                }
              ]}
              useMarkdown
            /> */}
            <p className='inner'>
              <span className='main-heading-accent main-heading-accent__large'>
                5%
              </span>{' '}
              <span className='main-heading-accent main-heading-accent__small'>
                off
              </span>{' '}
              your <br className='non-mobile' />
              down payment, that is.
              <span className='sub-heading-asterisk'>*</span>
            </p>
          </div>
        </div>
        <p className='intro'>
          <ReactMarkdown source={sectionIntro} />
        </p>
      </div>
      <Picture
        src={heroImages?.desktop?.url + '?w=1920'}
        alt={heroImages?.desktop?.alt}
        className='hero-override-background-image'
        sources={[
          {
            media: `(min-width: ${theme.breakpoints.desktop})`,
            params: '?w=1920',
            srcSet: heroImages?.desktop?.url,
          },
          {
            media: `(min-width: ${theme.breakpoints.tablet})`,
            params: '?w=800',
            srcSet: heroImages?.tablet?.url,
          },
          {
            media: `(max-width: ${theme.breakpoints.tablet})`,
            params: '?w=800',
            srcSet: heroImages?.mobile?.url,
          },
        ]}
        isResponsive
      ></Picture>
      <div className='hero-override-section-secondary text-content'>
        <StyleByTextSearch
          node='h2'
          text={sectionHeader}
          className='secondary-section-content'
          searches={[
            {
              text: ['home', 'ownership'],
              classes: ['secondary-heading-accent'],
            },
          ]}
        />
        <div className='hero-override-links'>
          {links.slice(0, 2).map(({ type, path, icon, text }, i) => {
            return (
              <ButtonLink
                key={`hero-override-button-link-${i}`}
                isExternal={true}
                newTab={true}
                to={path}
                outline={false}
              >
                {text}
              </ButtonLink>
            );
          })}
        </div>
        {links?.[2] && (
          <Link
            to={getE2ePath(links[2].path)}
            isExternal={true}
            newTab={true}
            includeArrowIcon={false}
            className='hero-override-final-link'
          >
            {links[2].text}
          </Link>
        )}
      </div>
    </Styles>
  );
};

export default HeroOverride;
