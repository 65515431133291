import styled from 'styled-components'

const colors = {
  blueBlack: '#052A30',
  green: '#9bd51e',
  blue: '#0076BB'
}

export default styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;

  .non-mobile {
    display: block !important;
  }

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    .non-mobile {
      display: none !important;
    }
  }

  * {
    font-family: acumin-pro;
    box-sizing: border-box;
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    padding: 0;
    display: inline;
  }

  .text-content * {
    position: relative;
    z-index: 5;
  }

  .hero-override-background-image {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    pointer-events: none;
    z-index: 1;
    height: 100%;

    img {
      max-height: 50rem;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top right;
      pointer-events: none;
    }
    
  }

  .hero-override-section-main {
    position: relative;
    background-color: ${colors.green};
    color: ${colors.blueBlack};
    height: 30rem;
    width: 100%;
    padding-left: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .clouds-image {
      position: absolute;
      top: 13rem;
      left: 36rem;
    }

    .main-heading {
      margin-left: 3rem;
      margin-top: 3rem;

      .main-heading-accent {
        color: #0076BB;
        text-shadow: 5px 5px 0px ${colors.blueBlack};
      }
      
    }

    .sub-heading {
      width: 50rem;
      margin-left: 8rem;
      text-align: center;
      margin-top: 2.5rem;

      * {
        line-height: 4rem;
        font-weight: 800;
        font-size: 4rem;
      }

      .inner {
        display: inline-block;
      }

      .main-heading-accent {
        color: #0076BB;
        text-shadow: 5px 5px 0px ${colors.blueBlack};

        &__large {
          font-size: 9rem;
        }
        
        &__small {
          text-shadow: 2px 2px 0px ${colors.blueBlack};
          font-size: 4rem;
        }
        
      }
      
      .sub-heading-asterisk {
        font-size: 6rem;
      }
      
    }

    h1 {
      font-size: 9rem;
    }

    h2 {
      font-size: 6rem;
    }

    h3 {
      font-size: 4rem;
    }

    .intro {
      margin-bottom: .8rem;
      margin-top: 1rem;

      * {
        color: ${props => props.theme.colors.white};
        font-style: italic;
        font-weight: 400;
        font-size: 1.2rem;
      }
      
    }
    
  }

  .hero-override-section-secondary {
    background-color: ${colors.blue};
    padding: 2rem 3rem;
    
    .secondary-section-content {
      width: 60rem;
      display: block;
      color: ${props => props.theme.colors.white};
      font-size: 3rem;
      text-align: center;

      .secondary-heading-accent {
        color: ${colors.green};
      }
      
    }

    .hero-override-links {
      display: flex;
      margin-top: 2rem;
      justify-content: center;
      width: 60rem;
      
      a {
        font-size: 2rem;
        font-weight: 500;
        display: flex;
        height: 1.8rem;
        justify-content: center;
        align-items: center;
        border: 4px solid ${props => props.theme.colors.white};
        color: ${colors.blueBlack};
        background-color: ${props => props.theme.colors.white};
        padding: 1.8rem 3rem;

        &:hover {
          background-color: ${props => props.theme.colors.white}00;
          color: ${props => props.theme.colors.white};
        }
        
        &:last-child {
          margin-left: 2rem;
          border: 4px solid ${colors.green};
          color: ${colors.green};
          background-color: ${colors.green}00;

          &:hover {
            background-color: ${colors.green};
            color: ${props => props.theme.colors.white};
          }
          
        }
      }
    }

    .hero-override-final-link {
      font-weight: 400;
      font-size: 2rem;
      color: ${props => props.theme.colors.white};
      text-decoration: underline;
      margin-top: 2rem;
      width: 60rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
  }

  @media (max-width: ${props => props.theme.breakpoints.desktop}) {
    .hero-override-background-image {
      height: 39rem;

      img {
        height: 39rem;
        object-position: bottom right;
      }
      
    }
    
    .hero-override-section-main {
      height: 36rem;
      padding: 0;
      justify-content: flex-start;
      align-items: flex-start;
      padding-left: 1rem;

      .clouds-image {
        top: 15.5rem;
        left: 6rem;

        img {
          width: 9rem;
        }
        
      }

      .hero-override-background-image {
        height: 36rem;

        img {
          height: 36rem;
        }
        
      }

      .main-heading {
        margin-top: 0.5rem;
        margin-left: 0;
        display: flex;
        width: 100%;
        justify-content: center;

        .main-heading-accent {
          font-size: 8.5rem;
          left: -1.1rem;
        }

        h1, h2, h3, h4, h5, h6 {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
        }
        
      }

      .sub-heading {
        width: 100%;
        margin-left: 0;

        * {
          display: inline-block;
          line-height: 2.6rem;
        }

        .main-heading-accent {
          &__large {
            font-size: 4rem;
            font-weight: 800;
            text-shadow: 2px 2px 0px ${colors.blueBlack};
          }

          &__small {
            font-size: 2.5rem;
            font-weight: 800;
          }
        }

        .sub-heading-asterisk {
          font-size: 4rem;
        }

        .inner {
          letter-spacing: -1px;
          width: 15rem;
          left: -5.2rem;
          font-weight: 800;
          font-size: 2.5rem;
        }
        
      }

      .intro {
        width: 11.5rem;
        text-align: center;
        left: 1rem;
        line-height: 0;
        margin-top: 1rem;
        
        * {
          font-size: .8rem;
          line-height: 1rem;
          font-weight: 400;
        }
      }
      
    }

    .hero-override-main-heading {
      h1 {
        font-size: 6rem;
      }

      h2 {
        font-size: 3rem;
      }

      h3 {
        font-size: 2rem;
      }
    }

    .hero-override-section-secondary {
      position: relative;
      z-index: 6;
      padding-left: 0;
      padding-top: 0;
      padding: 2rem .5rem;
      
      .secondary-section-content {
        font-size: 2rem;
        font-weight: 800;
        letter-spacing: -1px;
        width: 100%;
      }

      .hero-override-links {
        width: 100%;
        
        a {
          font-size: 1rem;
          padding: 1.3rem 1.5rem;

          &:last-child {
            margin-left: 1rem;
          }
          
        }
      }

      .hero-override-final-link {
        margin-left: 0;
        width: 100%;
        padding: 0 2rem;
        font-size: 1.2rem;
      }
      
    }
    
  }

  @media (min-width: ${props => props.theme.breakpoints.tablet}) and (max-width: ${props => props.theme.breakpoints.desktop}) {
    .hero-override-section-main {
      height: 29rem;

      .main-heading {
        justify-content: flex-start;
      }

      .clouds-image {
        left: 14rem;
        
        img {
          width: 17.1875rem;
        }
      }

      .intro {
        width: 22rem;
        text-align: center;
      }
      
      .sub-heading {
        margin-top: 3rem;
        
        * {
          line-height: 3rem;
        }
        
        .inner {
          width: 30rem;
          left: 0.5rem;
          text-align: left;
        }
      }
    }

    .hero-override-background-image {
      height: 29rem;

      img {
        height: 29rem;
      }
      
    }

    .hero-override-section-secondary {
      position: relative;
      z-index: 6;
      padding: 2rem .5rem;

      .hero-override-links a {
        font-size: 2rem;
        padding: 2rem;
      }

      .secondary-section-content {
        margin-top: 2rem;
        font-size: 2.6rem;
      }
      
    }
    
  }

  
  @media (max-width: 350px) {
    .hero-override-section-main .main-heading h1, .hero-override-section-main .main-heading span.main-heading-accent {
      font-size: 4rem;
    }

    .hero-override-section-main .sub-heading .inner {
      left: 0rem !important;
    }

    .clouds-image {
      top: 10rem !important;
      left: 2rem !important;
    }

    .hero-override-section-secondary {
      .hero-override-links {
        flex-direction: column;

        a {
          width: 100%;
          margin: 0;

          &:last-child {
            margin-left: 0;
            margin-top: 1rem;
          }
          
        }
        
      }
    }
    
  }
  
`
