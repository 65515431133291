import styled from 'styled-components';

const AccordionStyles = styled.section`
  padding: 4.6875rem 1rem 6.8125rem;
  background: ${props => props.background || props.theme.colors.white};
  button {
    font-family: ${props => props.theme.fonts.primary};
  }

  .inner {
    max-width: ${props => props.theme.maxWidths.containerSmall};
    margin: auto;

    @media (min-width: ${props => props.theme.breakpoints.desktop}) {
      max-width: ${props => props.theme.maxWidths.accordion};
    }

    header {
      display: flex;
      flex-direction: row;
      align-items: flex-end;

      @media (prefers-reduced-motion: no-preference) {
        .isAnimated& {
          opacity: 0;
          transform: translateY(10px);
        }

        .active& {
          animation: 0.4s ease-in-out slide-in-up forwards;
        }
      }

      h2 {
        margin: 0;
        margin-right: 1rem;
        line-height: 1em;
      }

      a {
        font-size: ${props => props.theme.type.button.small};
        line-height: ${props => props.theme.lineHeights.button.small};
        margin-bottom: 0.1em;

        svg {
          bottom: 0.6em;
        }
      }
    }

    .items {
      display: flex;
      flex-direction: column;
      list-style: none;
      padding: 0;

      li {
        padding-left: 0;

        @media (prefers-reduced-motion: no-preference) {
          .isAnimated& {
            opacity: 0;
          }

          .active& {
            animation: 0.4s ease-in-out scale-in forwards;
          }
        }

        a.link {
          margin-bottom: 1.5rem;
          display: inline-block;
        }
      }
    }
  }

  ${props => props.theme.animations.scaleIn};
  ${props => props.theme.animations.slideInUp};
`;

export default AccordionStyles;
