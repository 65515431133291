import React from 'react';

export default () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='12.0024' cy='11.9414' r='11.75' fill='currentColor' />
    <path
      d='M15.5288 12.2086L10.2394 16.3794L10.2394 8.0378L15.5288 12.2086Z'
      fill='white'
    />
  </svg>
);
