import React, { useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';

import noop from '../../../util/noop';
import SimpleAnimation from '../../../styles/Components/SimpleAnimation';

const recursiveMap = (children, fn) =>
  React.Children.map(children, child => {
    if (!React.isValidElement(child)) {
      return child;
    }

    if (child.props.children) {
      child = React.cloneElement(child, {
        children: recursiveMap(child.props.children, fn),
      });
    }

    return fn(child);
  });

const addPropsToChild =
  (newProps = {}) =>
  child =>
    React.cloneElement(child, {
      ...child.props,
      ...newProps,
    });

/**
 * The inView component renders the children with 0 opacity until
 * the element is scrolled into view. Once in view it triggers
 * an animation to set the opacity to 1.
 *
 * Note: This HOC passes an inView prop to all of it's children
 * recursively, giving them access to this instance of the
 * react-intersection-observer hook.
 */

const InView = ({
  triggerOnce = true,
  threshold = 0.25,
  onInViewChange = noop,
  duration = 0.3,
  delay = 0.1,
  node = 'section',
  className = '',
  children,
  shouldAnimate,
  shouldSlide = false,
  slideDirection = 'up',
  passInViewToChildren = true,
  id = '',
  ...props
}) => {
  const [ref, inView] = useInView({ triggerOnce, threshold });

  const actualChildren = useMemo(
    () =>
      recursiveMap(
        children,
        addPropsToChild(passInViewToChildren ? { inView } : {})
      ),
    [inView, children]
  );

  useEffect(() => {
    onInViewChange(inView);
  }, [inView]);

  const Component = useMemo(() => SimpleAnimation[node], [node]);

  return (
    <Component
      ref={ref}
      shouldAnimate={shouldAnimate}
      animateWhen={inView}
      shouldSlide={false}
      duration={duration}
      delay={delay}
      waitUntilInView={false}
      className={className}
      // shouldSlide={shouldSlide}
      slideDirection={slideDirection}
      id={id}
      {...props}
    >
      {actualChildren}
    </Component>
  );
};

export default InView;
