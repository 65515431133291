import React, { useState } from 'react';
import PropTypes, { oneOf } from 'prop-types';
import { useInView, InView } from 'react-intersection-observer';
import BlurbsStyles from './BlurbsThree.styled';
import ButtonVideo from '../Shared/ButtonVideo';
import Modal from '../Shared/Modal';
import Video from '../Shared/Video';
import noop from '../../util/noop';

const BlurbsThree = ({
  video = null,
  title = '',
  columnOne = null,
  columnTwo = null,
  columnThree = null,
  children,
  isAnimated = true,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.25 });

  const handlePlayClick = handler => () => {
    setModalOpen(true);
    handler();
  };

  return (
    <BlurbsStyles
      ref={ref}
      className={`block-blurbs_three ${isAnimated ? 'isAnimated' : ''} ${
        inView ? 'active' : ''
      }`}
    >
      <div className='inner'>
        <header>
          {video && video.content && (
            <ButtonVideo onClick={handlePlayClick(video.onClick || noop)}>
              Watch Video
            </ButtonVideo>
          )}
          {title && <h2>{title}</h2>}
          {children}
        </header>
        <InView triggerOnce={true} threshold={0.25}>
          {({ ref: columnsRef, inView: columnsInView }) => (
            <div
              ref={columnsRef}
              className={`columns ${columnsInView ? 'active' : ''}`}
            >
              <div className='column'>{columnOne}</div>
              <div className='column'>{columnTwo}</div>
              <div className='column'>{columnThree}</div>
            </div>
          )}
        </InView>
      </div>
      {modalOpen && (
        <Modal onClose={() => setModalOpen(false)}>
          <Video {...video} />
        </Modal>
      )}
    </BlurbsStyles>
  );
};

BlurbsThree.propTypes = {
  video: PropTypes.shape({
    source: PropTypes.oneOf(['Wistia', 'YouTube']),
    content: PropTypes.string,
  }),
  title: PropTypes.string,
  columnOne: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  columnTwo: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  columnThree: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  isAnimated: PropTypes.bool,
};

export default BlurbsThree;
