import styled from 'styled-components';
import { proportionToHex } from '../../../util/colors';

const mixins = {
  onStyles: config =>
    `fill: ${config.onColor}; filter: drop-shadow(${config.shadow.offset}px ${
      config.shadow.offset
    }px ${config.shadow.blur}px rgba(0, 0, 0, ${
      config.shadow.opacity
    })) drop-shadow(${config.glow1.offset}px ${config.glow1.offset}px ${
      config.glow1.blur
    }px ${config.glow1.color}${proportionToHex(
      config.glow1.opacity
    )}) drop-shadow(${config.glow2.offset}px ${config.glow2.offset}px ${
      config.glow2.blur
    }px ${config.glow2.color}${proportionToHex(config.glow2.opacity)});`,
  offStyles: config =>
    `fill: ${config.offColor}; filter: drop-shadow(${config.shadow.offset}px ${
      config.shadow.offset
    }px ${config.shadow.blur}px rgba(0, 0, 0, ${
      config.shadow.opacity
    })) drop-shadow(${config.glow1.offset}px ${config.glow1.offset}px ${
      config.glow1.blur
    }px ${config.glow1.color}${proportionToHex(0)}) drop-shadow(${
      config.glow2.offset
    } ${config.glow2.offset}px ${config.glow2.blur}px ${
      config.glow2.color
    }${proportionToHex(0)});`,
};

const getAnimationName = group => `neon-svg-anim-group-${group}`;

const generateCssAnimations = (config, groups) => {
  const groupKeyframes = groups.map(group => {
    const actualFrames = config.frames.reduce((output, f) => {
      if (f.duration !== null && f.duration !== undefined) {
        if (f.duration > 0) {
          for (let i = 1; i <= f.duration; i++) {
            output.push(f);
          }
        }
      } else {
        output.push(f);
      }

      return output;
    }, []);

    const animationFrames = actualFrames
      .filter(
        f =>
          f.on === true ||
          f.on === false ||
          f.on.includes(group) ||
          f.off.includes(group)
      )
      .map(
        v => v.on === true || (v.on instanceof Array && v.on?.includes(group))
      );

    if (animationFrames.length === 1)
      throw 'Cannot generate keyframes with only one frame';
    if (
      config.transitionDuration * animationFrames.length >
      config.animationDuration
    )
      throw `Transition duration cannot be greater than ${
        config.animationDuration
      }ms. The transition for this method must be at or less than ${Math.floor(
        config.animationDuration / animationFrames.length
      )}`;
    const msPerFrame = config.animationDuration / animationFrames.length;
    const percentPerFrame = Math.floor(
      (msPerFrame / config.animationDuration) * 100
    );
    const percentPerTransition = parseFloat(
      ((config.transitionDuration / config.animationDuration) * 100).toFixed(3)
    );
    const updatedFrames = animationFrames
      .reduce((output, isOn, i, a) => {
        if (i === 0) {
          output.push({
            at: 0,
            on: isOn,
          });
        } else {
          const prev = output[output.length - 1];
          if (isOn !== prev.on) {
            output.push({
              at: i * percentPerFrame,
              on: isOn,
            });
          }
        }

        return output;
      }, [])
      .reduce((output, current, i, a) => {
        if (i === 0) output.push(current);
        else {
          const prev = output[output.length - 1];
          const transitionStart = {
            at: current.at - percentPerTransition,
            on: prev.on,
          };
          output.push(transitionStart);
          output.push(current);

          if (i >= a.length - 1) {
            output.push({
              at: 100 - percentPerTransition,
              on: current.on,
            });
            output.push({
              at: 100,
              on: a[0].on,
            });
          }
        }
        return output;
      }, []);

    return {
      group,
      keyframes: updatedFrames,
    };
  });

  const cssKeyframeStrings = groupKeyframes.map(({ group, keyframes }) => {
    const frameStyles = keyframes.map(
      ({ at, on }) =>
        `${parseFloat(parseFloat(at).toFixed(3))}% { ${
          on ? mixins.onStyles(config) : mixins.offStyles(config)
        } }`
    );
    return `@keyframes ${getAnimationName(group)} { ${frameStyles.join(' ')} }`;
  });

  const styles = cssKeyframeStrings.join(' ');
  return styles;
};

export const NeonPath = styled.path`
  animation-name: ${props => getAnimationName(props.group)};
  animation-duration: ${props => props.config.animationDuration / 1000}s;
  animation-fill-mode: forwards;
  animation-direction: normal;
  animation-iteration-count: infinite;
`;

export default styled.svg`
  overflow: visible;
  ${props => generateCssAnimations(props.config, props.groups)}
`;
