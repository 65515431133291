import React from 'react';

export default () => (
  <svg
    width='93'
    height='89'
    viewBox='0 0 93 89'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M58.2002 0.798828V35.1988H92.6002C92.6002 16.1988 77.2002 0.798828 58.2002 0.798828Z'
      fill='#91CCEF'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M50.1998 43.1988V8.79883C31.1998 8.79883 15.7998 24.1988 15.7998 43.1988C15.7998 62.1988 31.1998 77.5988 50.1998 77.5988C69.1998 77.5988 84.5998 62.1988 84.5998 43.1988H50.1998Z'
      fill='#00A550'
    />
    <path d='M44.3 61.8984H0V88.5984H44.3V61.8984Z' fill='#004B8C' />
    <path
      d='M12.5 82.499C16.6421 82.499 20 79.1412 20 74.999C20 70.8569 16.6421 67.499 12.5 67.499C8.35786 67.499 5 70.8569 5 74.999C5 79.1412 8.35786 82.499 12.5 82.499Z'
      fill='white'
    />
    <path d='M37.4 69.1992H25V72.7992H37.4V69.1992Z' fill='white' />
    <path d='M37.4 76.5996H25V80.1996H37.4V76.5996Z' fill='white' />
  </svg>
);
