import React from 'react';

export default (props, hrefMatch) =>
  ({ href, children }) => {
    const injectProps = href === hrefMatch ? props : {};

    return children.map(({ props: { nodeKey, children: subChildren } }) => (
      <a key={nodeKey} href={href} {...injectProps}>
        {subChildren}
      </a>
    ));
  };
