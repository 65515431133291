import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  list-style: none;
  display: flex;

  .custom-bullet {
    margin-right: 0.83rem;
  }

  ${({
    animated = false,
    currentListIndex = 0,
    animationDelayIncrement = 0.2,
    initialAnimationDelay = 0.8,
    inView,
  }) => {
    if (animated) {
      return `
        @media (prefers-reduced-motion: no-preference) {
          ${
            inView
              ? `
                opacity: 0;
                @keyframes animate {
                  from {
                    opacity: 0;
                    transform: translateX(6px);
                  }
                  to {
                    opacity: 1;
                    transform: translateX(0px);
                  }
                }
      
                animation: 0.4s ease-in-out animate forwards;
                animation-delay: ${initialAnimationDelay + (currentListIndex + 1) * animationDelayIncrement}s;
              `
              : `
                opacity: 0;
              `
          }

        }
      `;
    }
  }}
`;

const customList =
  (
    config = {
      bullet: <></>,
      bulletIconProps: {},
      wrapperClassName: '',
      animate: false,
      inView: false,
    }
  ) =>
  ({ children }) => {
    const {
      bullet: Bullet,
      wrapperClassName,
      bulletIconProps,
      animate,
      inView,
    } = config;

    return children.map((child, index) => {
      return (
        <Wrapper
          className={wrapperClassName}
          key={`${child.key}-${index}`}
          animated={animate}
          currentListIndex={index}
          inView={inView}
        >
          {Bullet && (
            <div className='custom-bullet'>
              <Bullet {...bulletIconProps} />
            </div>
          )}
          {child}
        </Wrapper>
      );
    });
  };

export default customList;
