import styled from 'styled-components';

const reverseCtaPosition = props => props.ctaPosition === props.ctaPositions.top ? '-reverse' : '';

const HeroVideoStyles = styled.section`
  background: ${props => props.config.colors.background};
  padding: 3.625rem ${props => props.theme.padding.inner};
  position: relative;
  overflow: hidden;

  ${props => {
    const { videoStyle, theme, config, videoStyles : { fullTop } } = props;
    let output = ``;

    if (config?.sizing?.titleMaxWidth && config?.sizing?.titleMaxWidthMobile) {
      output += `
        > .inner .primary h1 {
          max-width: ${config.sizing.titleMaxWidth};

          @media (max-width: ${theme.breakpoints.tablet}) {
            max-width: ${config.sizing.titleMaxWidthMobile};
          }
          
        }
      `
    }

    if (videoStyle === fullTop) {
      output += `
        > .inner .primary .links-and-cta-wrapper .links a {
          @media (min-width: ${theme.breakpoints.desktop}) {
            font-size: 16px;
          }
        }

        @media (max-width: ${theme.breakpoints.desktop}) {
          .inner {
            display: flex;
            flex-direction: column-reverse !important;
          }

          .inner .secondary {
            margin: 0 !important;
            margin-bottom: 3.4375rem !important;
          }

          .inner .primary {
            margin-bottom: 0;
          }
          
          > .inner .links-and-cta-wrapper {
            flex-direction: row${reverseCtaPosition(props)} !important;
          }
        }

        @media (max-width: ${theme.breakpoints.tablet}) {
          padding: 0;
          > .inner {
            flex-direction: column-reverse !important;
            max-width: 100vw !important;

            > .primary {
              padding: 3rem ${theme.padding.inner} 3.625rem;
            }
            
            > .secondary {
              margin-top: 0 !important;
            }
          }
        }

        @media (max-width: 345px) {
          > .inner .links-and-cta-wrapper {
            flex-direction: column${reverseCtaPosition(props)} !important;
          }
        }
      `;
    }

    return output;
    
  }}

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 4.625rem ${props => props.theme.padding.inner};
  }

  @media (min-width: ${props => props.theme.breakpoints.desktop}) {
    padding: 6.3125rem ${props => props.theme.padding.inner} 9.4375rem;
  }

  .links a {
    display: flex;
    align-items: center;
  }

  .play-button-circle {
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.04);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.04);
    box-shadow: 0px 24px 32px 0px rgba(0, 0, 0, 0.04);
    @media (max-width: ${props => props.theme.breakpoints.tablet}) {
      width: 64px;
      height: 64px;
      top: calc(50% - 32px) !important;

      svg {
        width: .6rem;
        padding-left: .3rem;
      }
    }
  }

  .background-circle {
    background: ${props => props.config.colors.backgroundCircle};
    width: 52.125rem;
    height: 52.125rem;
    border-radius: 100%;
    position: absolute;
    top: calc(100% - 14.3125rem);
    left: 0;
    z-index: 1;

    @media (prefers-reduced-motion: no-preference) {
      .isAnimated& {
        opacity: 0;
        transition: all 0.4s ease-in-out;
        transition-delay: 0.4s;
      }
    }

    .active& {
      opacity: 1;
    }

    @media (min-width: ${props => props.theme.breakpoints.tablet}) {
      top: calc(100% - 19.6875rem);
      left: 50%;
      transform: translateX(-50%);
    }

    @media (min-width: ${props => props.theme.breakpoints.desktop}) {
      top: 50%;
      left: calc(100% - 30rem);
      transform: translateY(-50%);
    }
  }

  > .inner {
    display: flex;
    margin: auto;
    z-index: 2;
    position: relative;

    flex-direction: column;
    max-width: ${props => props.theme.maxWidths.containerSmall};
    @media (min-width: ${props => props.theme.breakpoints.desktop}) {
      max-width: ${props => props.theme.maxWidths.container};
    }

    @media (min-width: ${props => props.theme.breakpoints.desktop}) {
      flex-direction: row;
      align-items: center;
    }

    h1 {
      color: ${props => props.config.colors.title};
      margin: 0;
    }

    .links-and-cta-wrapper {
      display: flex;
      flex-direction: column${props => reverseCtaPosition(props)};
      
      @media (max-width: ${props => props.theme.breakpoints.desktop}) {
        display: flex;
        flex-direction: column${props => reverseCtaPosition(props)};
        justify-content: space-between;
      }
    }

    > .primary,
    > .secondary {
      @media (min-width: ${props => props.theme.breakpoints.desktop}) {
        width: 50%;
      }
    }

    > .primary,
    .links-and-cta-wrapper {
      @media (min-width: ${props => props.theme.breakpoints.desktop}) {
        max-width: 31rem;
        flex-shrink: 1;
      }

      @media (prefers-reduced-motion: no-preference) {
        .isAnimated& {
          opacity: 0;
          transform: translateY(10px);
        }

        .active& {
          animation: 0.4s ease-in-out slide-in-up forwards;
        }
      }

      > .content {
        color: ${props => props.config.colors.content};
        margin-bottom: 2rem;

        * {
          color: currentColor;
        }
      }

      > .links {
        display: flex;
        flex-direction: row;

        a {
          width: max-width;

          &:nth-child(2) {
            margin-left: 1rem;
          }
        }
      }

      > .cta {
        color: ${props => props.theme.colors.white};
        ${
          props => props.ctaPosition === props.ctaPositions.top
            ? 'margin-bottom: 1.875rem;'
            : 'margin-top: 1.875rem;'
        }

        p {
          margin: 0;
          font-size: ${props => props.theme.type.caption};
          line-height: ${props => props.theme.lineHeights.caption.default};
          letter-spacing: ${props => props.theme.letterSpacing.caption};
        }

        a {
          margin-left: ${props => props.config.spacing.ctaLeftMargin};
        }
      }
    }

    > .secondary {
      flex-grow: 1;
      margin-top: 3.125rem;

      picture {
        width: 100%;
        height: 0;
        padding-top: 65%;
        position: relative;
        display: block;

        @media (prefers-reduced-motion: no-preference) {
          .isAnimated& {
            opacity: 0;
            transform: translateY(10px);
            transition: all 0.4s ease-in-out;
            transition-delay: 0.2s;

            @media (min-width: ${props => props.theme.breakpoints.desktop}) {
              transform: translateX(-10px);
            }
          }
        }

        .active& {
          opacity: 1;
          transform: none;
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .thumbnail-text {
          position: absolute;
          top: 2rem;
          width: 16.25rem;
          left: calc(50% - 8.125rem);
          color: ${props => props.theme.colors.white};
          text-align: center;

          @media (max-width: ${props => props.theme.breakpoints.tablet}) {
            top: 1rem;
          }

          @media (max-width: ${props => props.theme.breakpoints.tablet}) {
            font-size: 26px;
            letter-spacing: -0.5px;
            line-height: 30px;
            font-weight: 600;
            top: 1rem;
            width: 11.3125rem;
            left: calc(50% - 5.65625rem);
          }
          
        }

        button {
          cursor: pointer;
          ${props => {
            if (!props.playButtonStyle || props.playButtonStyle === props.playButtonStyles.leftBottomFull) {
              return `
                position: absolute;
                bottom: 1rem;
                left: 1rem;
              `
            }

            if (props.playButtonStyle === props.playButtonStyles.centerCircle) {
              return `
                position: absolute;
                top: calc(50% - 2.5rem);
                left: calc(50% - 2.5rem);
              `
            }
          }}

          p {
            line-height: 1em;
          }

          &:hover,
          &:active,
          &:focus {
            background: ${props => props.theme.colors.white};
            color: ${props => props.theme.colors.vmfGreen2};
          }
        }
      }

      @media (min-width: ${props => props.theme.breakpoints.tablet}) {
        margin-top: ${props => props.config.videoMargin};
      }

      @media (min-width: ${props => props.theme.breakpoints.desktop}) {
        margin-left: 6.375rem;
        margin-top: 0;
      }
    }
  }

  .modal {
    .content {
      overflow: hidden;
    }
  }

  ${props => props.theme.animations.slideInUp};
`;

export default HeroVideoStyles;