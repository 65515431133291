import React from 'react';
import { Link } from 'react-router-dom';

export default ({ href, children }) =>
  href[0] === '/' && !href.includes(':newtab') ? (
    <Link to={href}>{children}</Link>
  ) : (
    <a
      target='_blank'
      href={href.replace(':newtab', '')}
      rel='noopener noreferrer'
    >
      {children}
    </a>
  );
