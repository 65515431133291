import React from 'react';
import Styles from './HeroSection.styled';
import PropTypes from 'prop-types';
import * as HeroVersions from './Versions';

const Hero = ({ version = '', ...props }) => {
  switch (version) {
    case 'Hero Override':
      return <HeroVersions.HeroOverride {...props} />;
    default:
      return <></>;
  }
};

const HeroSection = ({
  version = '',
  sectionHeader = '',
  sectionIntro = '',
  sectionLabel = '',
  sectionSubHeader = '',
  heroImages = {},
  links = [],
}) => {
  return (
    <Styles>
      <Hero
        version={version}
        sectionHeader={sectionHeader}
        sectionIntro={sectionIntro}
        sectionLabel={sectionLabel}
        sectionSubHeader={sectionSubHeader}
        heroImages={heroImages}
        links={links}
      />
    </Styles>
  );
};

HeroSection.propTypes = {
  version: PropTypes.string,
  sectionHeader: PropTypes.string,
  sectionIntro: PropTypes.string,
  heroImages: PropTypes.shape({
    desktop: {
      alt: PropTypes.string,
      url: PropTypes.string,
    },
    tablet: {
      alt: PropTypes.string,
      url: PropTypes.string,
    },
    mobile: {
      alt: PropTypes.string,
      url: PropTypes.string,
    },
  }),
  links: PropTypes.arrayOf(
    PropTypes.shape({
      type: 'Button' || 'Link',
      path: PropTypes.string,
      icon: null, // Unsure of this data type, possibly media type, not currently in use
      text: PropTypes.string,
    })
  ),
};

export default HeroSection;
