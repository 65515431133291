import axios from 'axios';
import { getBaseApiUrl, getRequestHeaders } from '../util/config';

export const getBlogPosts = async (
  category = 'all',
  searchCriteria = '',
  skip = 0,
  limit = 20,
  exclude = []
) =>
  await axios.get(
    `${getBaseApiUrl()}/Contentful/BlogPosts/${category}?searchCriteria=${searchCriteria}&skip=${skip}&limit=${limit}${exclude.length ? `&exclude=${exclude.join(',')}` : ''}`,
    {
      headers: getRequestHeaders(),
    }
  );

export const getBlogPost = async slug =>
  await axios.get(`${getBaseApiUrl()}/Contentful/BlogPost/?slug=${slug}`, {
    headers: getRequestHeaders(),
  });
