import React from 'react';
import PropTypes from 'prop-types';
import ButtonStyles from './Button.styled';
import noop from '../../util/noop';

const Button = ({
  children,
  appearance = 'dark',
  outline = false,
  size = 'large',
  disabled = false,
  onClick = noop,
  fullwidth = false,
  contentfulColors = '',
  inverse = false,
  ...props
}) => (
  <ButtonStyles
    appearance={appearance}
    outline={outline}
    size={size}
    disabled={disabled}
    onClick={onClick}
    fullwidth={fullwidth}
    contentfulColors={contentfulColors}
    inverse={inverse}
    {...props}
  >
    {children}
  </ButtonStyles>
);

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  appearance: PropTypes.oneOf(['light', 'dark']),
  size: PropTypes.oneOf(['large', 'small']),
  outline: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Button;
