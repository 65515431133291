import styled from 'styled-components';

export default styled.button`
  font-weight: 900;
  text-transform: uppercase;
  color: ${props => props.theme.colors.vmfGreen2};
  font-size: ${props => props.theme.type.label.large};
  line-height: ${props => props.theme.lineHeights.label.large};
  letter-spacing: ${props => props.theme.letterSpacing.label};
  font-family: ${props => props.theme.fonts.primary};
  background: transparent;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.4rem;
  padding: 0.7rem 0.5rem 0.4rem 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    color: ${props => props.theme.colors.vmfBlue};
  }

  svg {
    margin-right: 0.5rem;
    margin-top: -0.4em;
  }
`;
