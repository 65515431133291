import React from 'react';

export default () => (
  <svg
    width='103'
    height='80'
    viewBox='0 0 103 80'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M51.0674 14.3564L22.5044 33.0465V79.7716H43.191V58.9183C43.191 54.5919 46.7397 51.0443 51.0674 51.0443C55.3951 51.0443 58.9439 54.5919 58.9439 58.9183V79.7716H79.6304V33.0465L51.0674 14.3564Z'
      fill='#00A550'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M67.8589 79.5117C67.5993 78.5599 67.5127 77.6946 67.5127 76.6563C67.5127 69.9936 72.8791 64.6289 79.5438 64.6289C83.8715 64.6289 87.5934 66.8786 89.7572 70.3397C90.8824 69.9071 92.0942 69.6475 93.3925 69.6475C98.6724 69.6475 103 73.9739 103 79.2521C103 79.3387 103 79.5117 103 79.5982H67.8589V79.5117Z'
      fill='#004B8C'
    />
    <path
      d='M77.8993 21.5382C83.6356 21.5382 88.2858 16.8894 88.2858 11.1548C88.2858 5.42027 83.6356 0.771484 77.8993 0.771484C72.1629 0.771484 67.5127 5.42027 67.5127 11.1548C67.5127 16.8894 72.1629 21.5382 77.8993 21.5382Z'
      fill='#91CCEF'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M27.6975 54.4187C27.6975 46.8042 21.4655 40.5742 13.8487 40.5742C6.23193 40.5742 0 46.8042 0 54.4187C0 61.1679 4.84706 66.7922 11.2521 68.0036V79.5118H16.4454V68.0036C22.8504 66.7922 27.6975 61.1679 27.6975 54.4187Z'
      fill='#004B8C'
    />
  </svg>
);
