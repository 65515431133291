import React from 'react';
import ButtonStyles from './styled';
import PlayCircleIcon from '../Icons/PlayCircle';

const ButtonVideo = ({ children, ...props }) => (
  <ButtonStyles {...props}>
    <PlayCircleIcon />
    {children}
  </ButtonStyles>
);

ButtonVideo.propTypes = {};

export default ButtonVideo;
